import PropTypes from 'prop-types';
import { memo } from 'react';
import { twMerge } from 'tailwind-merge';

import Text from 'shopper/components/Text';

import LikeButton from 'components/OfferLikes/LikeButton';
import UserAvatar from 'components/UserAvatar';

import { getShortenedTimeTo } from 'lib/date';

import Authors from '../../../Authors';

const OfferGridCardFooter = ({
  authors = [],
  className,
  gaEventCategory,
  offerPublished,
  offerId,
  likesCount,
  userId,
  userName,
  userPhoto,
}) => (
  <div
    className={twMerge(
      'flex w-full items-center justify-between border-t border-t-neutral-high-200 p-2 dark:border-t-neutral-low-300 md:py-2 lg:px-3',
      className
    )}
  >
    <div className="grid grid-cols-[repeat(2,auto)] grid-rows-[repeat(2,auto)] items-center gap-x-1 md:gap-x-2 md:gap-y-1">
      {authors.length > 0 ? (
        <Authors
          authors={authors}
          className="col-start-1 row-span-2 row-start-1 md:row-span-1"
          firstAuthorId={userId}
        />
      ) : (
        <UserAvatar
          size="size6"
          userId={userId}
          userName={userName}
          userPhoto={userPhoto}
        />
      )}
      <Text
        className="col-start-2 row-start-1 line-clamp-1 w-14 truncate text-neutral-low-100 dark:text-neutral-high-400 md:row-span-1 md:w-full"
        size="size3"
        bold
      >
        {userName}
      </Text>
      <Text
        className="col-start-2 row-start-2 text-neutral-low-100 dark:text-neutral-high-400 md:col-span-2 md:col-start-1"
        size="size3"
        suppressHydrationWarning
      >
        {getShortenedTimeTo(offerPublished)}
      </Text>
    </div>
    <LikeButton
      gaEventCategory={gaEventCategory}
      likesCount={likesCount}
      offerId={offerId}
      size="size4"
    />
  </div>
);

OfferGridCardFooter.propTypes = {
  authors: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      username: PropTypes.string.isRequired,
      photo: PropTypes.string.isRequired,
      typeName: PropTypes.string.isRequired,
      specialityName: PropTypes.string,
      level: PropTypes.number.isRequired,
      link: PropTypes.string.isRequired,
    })
  ),
  gaEventCategory: PropTypes.string.isRequired,
  offerPublished: PropTypes.string.isRequired,
  userId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  userName: PropTypes.string.isRequired,
  userPhoto: PropTypes.string.isRequired,
};

export default memo(OfferGridCardFooter);
